import { Spin } from 'antd'
import Loader from 'components/ui/Loader'
import dayjs from 'dayjs'
import { addShiurToFavorite, addShiurToFavoriteVariables } from 'graphql/mutation/__generated__/addShiurToFavorite'
import {
  removeShiurFromFavorite,
  removeShiurFromFavoriteVariables,
} from 'graphql/mutation/__generated__/removeShiurFromFavorite'
import { ADD_SHIUR_TO_FAVORITE } from 'graphql/mutation/addShiurToFavorite'
import { REMOVE_SHIUR_FROM_FAVORITE } from 'graphql/mutation/removeShiurFromFavorite'
import { getDateWithoutTimezone } from 'helpers/getDateWithoutTimezone'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useMutation } from '@apollo/client'

import ShiurimCard, { ShiurimItem, ShiurimSize } from '../../Cards/ShiurimCard'
import PlayAllShiursByDateButton from '../../Shiurim/PlayAllShiursByDateButton'
import { DateText, Description, InfiniteScrollStyled, Item, LoaderWrapper, TitleRow, Wrapper } from './styled'

type Props = {
  items?: ShiurimItem[]
  size?: ShiurimSize
  history?: boolean
  openRegisterToContinueModal?: () => void
  refetch?: (variables?: any) => Promise<any>
  onFetchMore?: () => void
  next?: boolean
  isPlayAllTodayAvailable?: boolean
  loading: boolean
  loadingNext: boolean
}

type GroupedItemsArr = {
  formattedDate: string
  originalDate: string
  items: ShiurimItem[]
}[]

function ShiurimListByDate({
  items,
  size,
  history,
  isPlayAllTodayAvailable,
  openRegisterToContinueModal,
  refetch,
  onFetchMore,
  next,
  loadingNext,
  loading,
}: Props) {
  const { pathname } = useLocation()

  const [groupedItems, setGroupedItems] = useState<GroupedItemsArr>([])

  useEffect(() => {
    const newGroupedItems =
      items?.reduce<{ [key: string]: ShiurimItem[] }>((groups, el) => {
        const elDate = getDateWithoutTimezone(history ? el?.listenedAt : el.publicationDate)
          .toDate()
          .toISOString()

        if (!groups[elDate]) {
          groups[elDate] = []
        }
        groups[elDate].push(el)
        return groups
      }, {}) || {}

    const groupArrays = Object.keys(newGroupedItems).map((originalDate) => {
      const dayjsDate = dayjs(originalDate)

      const formattedDate = dayjsDate.isToday()
        ? dayjsDate.format('[Today,] MMMM D')
        : dayjsDate.isYesterday()
        ? dayjsDate.format('[Yesterday,] MMMM D')
        : dayjsDate.format('MMMM D')
      return {
        formattedDate,
        originalDate,
        items: newGroupedItems[originalDate],
      }
    })

    const sortedGroupArrays = groupArrays.sort(
      (a, b) => new Date(b.originalDate).valueOf() - new Date(a.originalDate).valueOf()
    )

    setGroupedItems(sortedGroupArrays)
  }, [items])

  const [addShiurToFavorite, { loading: addShiurToFavoriteLoading }] = useMutation<
    addShiurToFavorite,
    addShiurToFavoriteVariables
  >(ADD_SHIUR_TO_FAVORITE)
  const [removeShiurFromFavorite, { loading: removeShiurFromFavoriteLoading }] = useMutation<
    removeShiurFromFavorite,
    removeShiurFromFavoriteVariables
  >(REMOVE_SHIUR_FROM_FAVORITE)

  const onToggleFavorite = async (shiurId: string, favorite: boolean) => {
    if (addShiurToFavoriteLoading || removeShiurFromFavoriteLoading) return

    if (favorite) {
      await removeShiurFromFavorite({ variables: { shiurId } })
    } else {
      await addShiurToFavorite({ variables: { shiurId } })
    }

    if (refetch) await refetch()
  }

  if (loading) return <Loader noHeight />

  return (
    <>
      <InfiniteScrollStyled
        dataLength={groupedItems?.length}
        next={onFetchMore}
        hasMore={next}
        loader={null}
        scrollableTarget="scrollable-wrapper"
      >
        {groupedItems.map((el) => (
          <Item key={el.originalDate}>
            <TitleRow>
              <DateText>{el.formattedDate}</DateText>
              {isPlayAllTodayAvailable && (
                <PlayAllShiursByDateButton
                  isPage
                  date={dayjs(el.originalDate)}
                  isFollowing={pathname === ROUTE_PATH.FOLLOWING}
                />
              )}
            </TitleRow>
            {dayjs(el.originalDate).isToday() && (
              <Description>{"A new day brings new shiurim. Here's what we have for you today:"}</Description>
            )}

            <Wrapper>
              {el.items.map((shiur) => (
                <ShiurimCard
                  key={shiur.shiurId}
                  index={items.findIndex((el) => el.shiurId === shiur.shiurId)}
                  size={size}
                  history={history}
                  onToggleFavorite={onToggleFavorite}
                  openRegisterToContinueModal={openRegisterToContinueModal}
                  {...shiur}
                />
              ))}
            </Wrapper>
          </Item>
        ))}
        <LoaderWrapper>
          <Spin spinning={loadingNext} />
        </LoaderWrapper>
      </InfiniteScrollStyled>
    </>
  )
}

export default ShiurimListByDate
