import { gql } from '@apollo/client'

export const CREATE_USER_SESSION = gql`
  mutation createUserSession($sessionParams: UserSessionKeyInputDto!, $sessionData: UserSessionDataInputDto!) {
    createUserSession(sessionParams: $sessionParams, sessionData: $sessionData) {
      sessionKey
      playerSpeed
    }
  }
`
