import { Button as ANTDButton, Spin } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`

export const Button = styled(ANTDButton)`
  height: 42px;

  display: flex;
  align-items: center;

  & > span {
    margin-right: 8px;
  }
`

export const Loader = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #ffffff;
  }
`
