import { gql } from '@apollo/client'

export const GET_USERS_SESSION = gql`
  query getUsersSession($sessionParams: UserSessionKeyInputDto!) {
    getUsersSession(sessionParams: $sessionParams) {
      sessionKey
      playerSpeed
    }
  }
`
