import { Flex } from 'components/common/Flex'
import { SearchProps } from 'components/ui/Header'
import { deviceWidth } from 'constants/responsive'
import { getAllShiurims, getAllShiurimsVariables } from 'graphql/query/__generated__/getAllShiurims'
import { GET_ALL_SHIURIMS } from 'graphql/query/getAllShiurims'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import ShiurimList from '../../Lists/ShiurimList'
import PlayAllShiursButton from '../../Shiurim/PlayAllShiursButton'
import ShiurimFilter, { ShiurimFilters } from '../../Shiurim/ShiurimFilter'
import { Count, FiltersRow, SearchStyled, SortButton, SortButtonText, SortIcon } from './styled'

type Props = { search: string; searchProps: SearchProps; openRegisterToContinueModal: () => void }

const initialPagination = {
  take: 30,
  page: 1,
}

function ShiurimsWithFilter({ search, searchProps, openRegisterToContinueModal }: Props) {
  const { id } = useParams()
  const { setQueryInfo } = useQueryInfo()

  const [filters, setShiurimFilters] = useState<ShiurimFilters>({})
  const [sort, setSort] = useState<'ASC' | 'DESC'>('DESC')

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const variables = useMemo(
    () => ({
      ...initialPagination,
      searchParams: {
        title: search,
        startDate: filters.period?.startDate,
        endDate: filters.period?.endDate,
        series: [id],
        topics: filters.topics?.map((el) => el.id),
        subtopics: filters.subtopics?.map((el) => el.id),
        dateOrderType: sort,
      },
    }),
    [filters.period?.endDate, filters.period?.startDate, filters.subtopics, filters.topics, id, search, sort]
  )
  const { data, loading, fetchMore } = useQuery<getAllShiurims, getAllShiurimsVariables>(GET_ALL_SHIURIMS, {
    variables,
  })

  const queryInfo = useMemo(() => ({ query: GET_ALL_SHIURIMS, variables }), [variables])

  useEffect(() => {
    setQueryInfo(queryInfo)
  }, [queryInfo, setQueryInfo, variables])

  const onFetchMore = async () => {
    setLoadingNext(true)

    const variables = { take: initialPagination.take, page: page + 1 }

    await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getAllShiurims: {
            ...previousQueryResult.getAllShiurims,
            ...fetchMoreResult.getAllShiurims,
            items: [...previousQueryResult.getAllShiurims.items, ...fetchMoreResult.getAllShiurims.items],
          },
        }
      },
    })

    setQueryInfo({ query: GET_ALL_SHIURIMS, variables })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const { searchValue, onChangeSearch, searchPlaceholder } = searchProps

  const isMobile = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])
  return (
    <>
      <FiltersRow>
        <Count>{data?.getAllShiurims?.meta?.total} Shiurim</Count>
        {!isMobile && <SearchStyled value={searchValue} onChange={onChangeSearch} placeholder={searchPlaceholder} />}
        <Flex alignCenter>
          <ShiurimFilter noSeries filters={filters} onChangeFilters={setShiurimFilters} />
          <SortButton onClick={() => setSort(sort === 'DESC' ? 'ASC' : 'DESC')}>
            <SortButtonText>{sort === 'DESC' ? 'Newest' : 'Oldest'}</SortButtonText>
            <SortIcon isASC={sort === 'ASC'} />
          </SortButton>
          <PlayAllShiursButton {...queryInfo} />
        </Flex>
      </FiltersRow>

      <ShiurimList
        items={data?.getAllShiurims?.items}
        next={data?.getAllShiurims?.meta?.next}
        loading={loading}
        loadingNext={loadingNext}
        onFetchMore={onFetchMore}
        openRegisterToContinueModal={openRegisterToContinueModal}
        oneColumn
      />
    </>
  )
}

export default withQueryInfo(ShiurimsWithFilter)
