import { Spin } from 'antd'
import dayjs from 'dayjs'
import { addShiurToFavorite, addShiurToFavoriteVariables } from 'graphql/mutation/__generated__/addShiurToFavorite'
import {
  removeShiurFromFavorite,
  removeShiurFromFavoriteVariables,
} from 'graphql/mutation/__generated__/removeShiurFromFavorite'
import { ADD_SHIUR_TO_FAVORITE } from 'graphql/mutation/addShiurToFavorite'
import { REMOVE_SHIUR_FROM_FAVORITE } from 'graphql/mutation/removeShiurFromFavorite'
import { getShiurimOrderedByDate_getShiurimOrderedByDate_items } from 'graphql/query/__generated__/getShiurimOrderedByDate'
import { getDateWithoutTimezone } from 'helpers/getDateWithoutTimezone'
import useFade from 'hooks/useFade'
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'

import { useMutation } from '@apollo/client'

import ShiurimCard, { ShiurimSize } from '../../Cards/ShiurimCard'
import PlayAllTodaysShiursButton from '../../Shiurim/PlayAllShiursByDateButton'
import { Date as DateText, Description, Item, LoaderMoreWrapper, LoaderWrapper, TitleRow, Wrapper } from './styled'

type Props = {
  items?: getShiurimOrderedByDate_getShiurimOrderedByDate_items[]
  size?: ShiurimSize
  openRegisterToContinueModal: () => void
  onFetchNext: () => Promise<void>
  onFetchPrev: () => Promise<void>
  prevLoadingRef: MutableRefObject<boolean>
  nextLoadingRef: MutableRefObject<boolean>
  prevLoading: boolean
  nextLoading: boolean
  loading: boolean
  selectedDate: dayjs.Dayjs
  isSelectedDateChangedByScroll: boolean
  prevLastFetchedDay: MutableRefObject<dayjs.Dayjs>
  nextLastFetchedDay: MutableRefObject<dayjs.Dayjs>
  onChangeDate?: (date: dayjs.Dayjs) => void
  onUnloadedDateChange?: (date: dayjs.Dayjs) => void
}

type GroupedItemsArr = {
  formattedDate: string
  originalDate: string
  items: getShiurimOrderedByDate_getShiurimOrderedByDate_items[]
}[]

function ShiurimListBidirectionalScroll({
  items,
  size,
  openRegisterToContinueModal,
  onFetchNext,
  onFetchPrev,
  prevLoading,
  nextLoading,
  prevLoadingRef,
  nextLoadingRef,
  loading,
  selectedDate,
  isSelectedDateChangedByScroll,
  prevLastFetchedDay,
  nextLastFetchedDay,
  onChangeDate,
  onUnloadedDateChange,
}: Props) {
  const prevScrollHeightRef = useRef(0)
  const selectedDateRef = useRef<dayjs.Dayjs>(selectedDate)
  // To avoid changing date during scrollIntoTheView()
  const isScrollToPending = useRef(false)
  const isNextPending = useRef(false)
  const isNextAvailable = useRef(false)
  const isPrevAvailable = useRef(true)
  const [groupedItems, setGroupedItems] = useState<GroupedItemsArr>([])
  const [isVisible, show, setShow, fadeProps] = useFade(false)

  const [addShiurToFavorite, { loading: addShiurToFavoriteLoading }] = useMutation<
    addShiurToFavorite,
    addShiurToFavoriteVariables
  >(ADD_SHIUR_TO_FAVORITE)
  const [removeShiurFromFavorite, { loading: removeShiurFromFavoriteLoading }] = useMutation<
    removeShiurFromFavorite,
    removeShiurFromFavoriteVariables
  >(REMOVE_SHIUR_FROM_FAVORITE)

  const onToggleFavorite = async (shiurId: string, favorite: boolean) => {
    if (addShiurToFavoriteLoading || removeShiurFromFavoriteLoading) return

    if (favorite) {
      await removeShiurFromFavorite({ variables: { shiurId } })
    } else {
      await addShiurToFavorite({ variables: { shiurId } })
    }
  }

  useEffect(() => {
    const container = document.querySelector('#scrollable-wrapper')

    container.addEventListener('scroll', handleScroll)
    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (loading) return setShow(true)
    setShow(false)
  }, [loading, setShow])

  useEffect(() => {
    // Cache current value of selectedDate, so I can receive actual date in closure
    selectedDateRef.current = selectedDate

    if (isSelectedDateChangedByScroll) return

    // find element with selectedDate
    const element = Array.from(document.querySelectorAll('.date-item')).find((element: HTMLElement) =>
      dayjs(element.dataset.date).isSame(selectedDateRef.current, 'date')
    )

    if (element) {
      scrollToSelectedDate(element)
    } else {
      // Clear all items
      onUnloadedDateChange(selectedDateRef.current)
      setGroupedItems([])
    }
  }, [selectedDate])

  useEffect(() => {
    const newGroupedItems =
      items?.reduce<{ [key: string]: getShiurimOrderedByDate_getShiurimOrderedByDate_items[] }>((groups, el) => {
        const elDate = getDateWithoutTimezone(el.publicationDate).toDate().toISOString()

        if (!groups[elDate]) {
          groups[elDate] = []
        }
        groups[elDate].push(el)
        return groups
      }, {}) || {}

    const groupArrays = Object.keys(newGroupedItems)
      .sort((a, b) => new Date(b).valueOf() - new Date(a).valueOf())
      .map((originalDate) => {
        const dayjsDate = dayjs(originalDate)

        const formattedDate = dayjsDate.isToday()
          ? dayjsDate.format('[Today,] MMMM D')
          : dayjsDate.isYesterday()
          ? dayjsDate.format('[Yesterday,] MMMM D')
          : dayjsDate.format('MMMM D')
        return {
          formattedDate,
          originalDate,
          items: newGroupedItems[originalDate],
        }
      })

    setGroupedItems(groupArrays)
  }, [items])

  useEffect(() => {
    if (nextLastFetchedDay.current.isToday()) {
      isNextAvailable.current = false
    } else {
      isNextAvailable.current = true
    }

    if (prevLastFetchedDay.current.add(-1, 'day').isBefore('2020', 'year')) {
      isPrevAvailable.current = false
    } else {
      isPrevAvailable.current = true
    }

    const container = document.querySelector('#scrollable-wrapper')
    const content = document.querySelector('#items-wrapper')

    // Record scroll position and allow fetchNext
    if (isNextPending.current) {
      container.scrollTop = container.scrollHeight - prevScrollHeightRef.current
      isNextPending.current = false
    }

    // fetch items until screen is filled if prev date available
    if (
      content.scrollHeight < container.clientHeight &&
      !prevLoadingRef.current &&
      !nextLoadingRef.current &&
      !loading &&
      !isNextPending.current &&
      isPrevAvailable.current
    ) {
      onFetchPrev()
      return
    }

    //fetch items if bottom reached and user doesn't scrolling
    if (
      isBottomReached() &&
      !prevLoadingRef.current &&
      !nextLoadingRef.current &&
      !loading &&
      !isNextPending.current &&
      isPrevAvailable.current
    ) {
      onFetchPrev()
      return
    }

    // fetch items until screen is filled if previous date unavailable
    if (
      content.scrollHeight < container.clientHeight &&
      !prevLoadingRef.current &&
      !nextLoadingRef.current &&
      !loading &&
      !isPrevAvailable.current &&
      isNextAvailable.current &&
      !isNextPending.current
    ) {
      onTopReached()
    }
  }, [groupedItems])

  const scrollToSelectedDate = useCallback(async (element: Element) => {
    isScrollToPending.current = true
    element.scrollIntoView({ behavior: 'smooth' })

    // Block scroll change selected date until scrolled to particular item
    setTimeout(() => {
      isScrollToPending.current = false
    }, 1000)
  }, [])

  const setCurrentDate = useCallback(() => {
    let closestElement: HTMLElement = null
    let closestDistance = Number.MAX_VALUE

    const calendar = document.querySelector('.calendar-section')
    const calendarRect = calendar.getBoundingClientRect()

    document.querySelectorAll('.date-item').forEach((element: HTMLElement) => {
      const rect = element.getBoundingClientRect()
      const distance = Math.sqrt(
        Math.pow(calendarRect.left - rect.left, 2) + Math.pow(calendarRect.bottom - rect.top, 2)
      )

      if (distance < closestDistance) {
        closestElement = element
        closestDistance = distance
      }
    })

    if (closestElement) {
      const date = closestElement.dataset.date

      if (selectedDateRef.current.isSame(date, 'date')) return

      const newDate = dayjs(date)

      onChangeDate(newDate)
    }
  }, [onChangeDate])

  const onTopReached = useCallback(async () => {
    const container = document.querySelector('#scrollable-wrapper')
    prevScrollHeightRef.current = container.scrollHeight

    await onFetchNext()
    isNextPending.current = true
  }, [onFetchNext])

  const isTopReached = () => {
    const container = document.querySelector('#scrollable-wrapper')

    return container.scrollTop === 0
  }

  const isBottomReached = () => {
    const container = document.querySelector('#scrollable-wrapper')

    return Math.floor(container.scrollTop + container.clientHeight + 100) >= Math.floor(container.scrollHeight) - 1
  }

  const fetchNewItems = useCallback(async () => {
    if (isBottomReached() && !prevLoadingRef.current && isPrevAvailable.current) {
      await onFetchPrev()
    }

    if (isTopReached() && !nextLoadingRef.current && !isNextPending.current && isNextAvailable.current) {
      await onTopReached()
    }
  }, [onFetchPrev, onTopReached])

  const handleScroll = useCallback(async () => {
    // To avoid changing date during scrollIntoTheView()
    if (!isScrollToPending.current) setCurrentDate()

    await fetchNewItems()
  }, [fetchNewItems, setCurrentDate])

  return (
    <>
      {isVisible && (
        <LoaderWrapper {...fadeProps} isVisible={isVisible && show}>
          <Spin />
        </LoaderWrapper>
      )}
      <div id="items-wrapper">
        <LoaderMoreWrapper top>
          <Spin spinning={nextLoading} />
        </LoaderMoreWrapper>

        {groupedItems.map((el) => (
          <Item className="date-item" key={el.originalDate} data-date={el.originalDate}>
            <TitleRow>
              <DateText>{el.formattedDate}</DateText>
              <PlayAllTodaysShiursButton isPage date={dayjs(el.originalDate)} />
            </TitleRow>
            {dayjs(el.originalDate).isToday() && (
              <Description>{"A new day brings new shiurim. Here's what we have for you today:"}</Description>
            )}

            <Wrapper>
              {el.items.map((el, i) => (
                <ShiurimCard
                  key={el.shiurId}
                  index={i}
                  size={size}
                  onToggleFavorite={onToggleFavorite}
                  openRegisterToContinueModal={openRegisterToContinueModal}
                  {...el}
                />
              ))}
            </Wrapper>
          </Item>
        ))}

        <LoaderMoreWrapper bottom>
          <Spin spinning={prevLoading} />
        </LoaderMoreWrapper>
      </div>
    </>
  )
}

export default ShiurimListBidirectionalScroll
