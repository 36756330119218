import { gql } from '@apollo/client'

export const UPDATE_USER_SESSION = gql`
  mutation updateUserSession($sessionParams: UserSessionKeyInputDto!, $sessionData: UserSessionDataInputDto!) {
    updateUserSession(sessionParams: $sessionParams, sessionData: $sessionData) {
      sessionKey
      playerSpeed
    }
  }
`
